import { axiosWithCreds, isIntentionalCancellation } from 'app/Utils/ajax.util'
import { getServiceBaseUrl } from './serviceUtils'
import { getBrandColorNumber } from 'app/Utils/general.utils'

// this is used to send our items to the backend, and sync up with what they send back
// (to handle out of stock, quantity issues, etc.)
// this also gives us our order ID, etc.
function sync ({
  orderId,
  items,
  user = {},
  clientToken,
  cancelToken = null
}) {
  const cartItems = items.map(item => {
    return {
      sku: item.productId,
      quantity: item.qty
    }
  })

  const method = 'POST'
  const url = (orderId) ? `${getServiceBaseUrl}/orders/${orderId}` : `${getServiceBaseUrl}/orders`

  return new Promise((resolve, reject) => {
    axiosWithCreds({
      method,
      url,
      data: {
        ...user,
        lineItems: cartItems,
        clientToken,
        // back-end requires 1 or 1 for booleans
        newsletterOptIn: (user && user.newsletterOptIn) ? 1 : 0
      },
      cancelToken
    })
      .then(({ data }) => resolve(data))
      .catch(err => {
        const { message } = err
        if (isIntentionalCancellation(message)) {
          return resolve() // intentional cancellation
        }

        console.warn('sync(): There was an error synchronizing the cart.', err)

        reject(err)
      })
  })
}

export function getColorNumberFromProduct (product, lower = true) {
  const color = product?.colors[0]
  if (typeof color === 'string') {
    return lower ? color.toLowerCase() : color
  }

  const cn = getBrandColorNumber(color) ?? ''

  return lower ? cn.toLowerCase() : cn
}

export default sync
