import './OptionsModal.scss'
import VectorImg from '../../../images/Vector.png'
import React, { useState, useEffect } from 'react'
import { getServiceBaseUrl } from 'app/Utils/serviceUtils'
import stringToBool from 'app/Utils/stringToBool'
import { axiosWithCreds } from 'app/Utils/ajax.util'

const POPUP_INITIAL_DELAY_MS = 1000 * 60 * 3
const IDLE_TIME_MS = 1000 * 30

function SurveyModal () {
  const [showModal, setShowModal] = useState(false)
  const lastEvent = React.useRef(new Date().getTime())
  const timeoutId = React.useRef()
  const isModalDisabled = React.useRef(false)
  // User activity
  useEffect(() => {
    const openModal = () => {
      if (isModalDisabled.current) return
      const el = document.getElementById('cartModal')
      if (new Date().getTime() - lastEvent.current > IDLE_TIME_MS && el === null) {
        setShowModal(true)
      }
    }

    timeoutId.current = setTimeout(openModal, IDLE_TIME_MS)

    const callback = (event) => {
      lastEvent.current = new Date().getTime()
      clearTimeout(timeoutId.current)
      timeoutId.current = setTimeout(openModal, IDLE_TIME_MS)
    }

    window.onclick = (event) => {
      if (!event.target.closest('#surveyModalContent')) {
        disableModal()
      }
    }
    document.addEventListener('mousemove', callback)
    document.addEventListener('keyup', callback)

    return () => {
      document.removeEventListener('mousemove', callback)
      document.removeEventListener('keyup', callback)
      if (timeoutId.current) clearTimeout(timeoutId.current)
    }
  }, [isModalDisabled])

  const disableModal = () => {
    if (timeoutId.current) clearInterval(timeoutId.current)
    isModalDisabled.current = true
    setShowModal(false)
  }

  useEffect(() => {
    let timeoutId
    axiosWithCreds.get(`${getServiceBaseUrl}/flags/siteSurveyEnabled`).then(data => data.data).then(data => {
      if (stringToBool(data.status)) {
        timeoutId = setTimeout(() => {
          const el = document.getElementById('cartModal')
          if (el === null) {
            setShowModal(true)
          }
        }, POPUP_INITIAL_DELAY_MS)
      } else {
        disableModal()
      }
    }).catch(err => {
      if (err) {
        console.error('Survey could not be shown.')
      }
    })

    return () => {
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId)
      }
    }
  }, [])

  return (
    showModal
      ? <section className={`OptionsModal modal is-active ${showModal ? 'nohide' : 'hide'}`} id='surveyModal' style={{ paddingLeft: '30px', paddingRight: '30px' }}>
      <div className='OptionsModal__bg modal-background' />
      <button onClick={() => disableModal()} className='OptionsModal__close-btn modal-close is-large' aria-label='close' />
      <div style={{ width: '40rem', zIndex: 100, textAlign: 'center' }} id='surveyModalContent' className='modal-content has-background-white'>
        <div style={{ width: '100%', zIndex: 100, textAlign: 'center' }}>
          <img style={{ paddingLeft: '37%', transform: 'scale(1.5)', marginBottom: '20px' }} src={VectorImg} alt='VectorImg' />
        </div>
        <h1 className='has-text-centered is-size-6 is-size-4-tablet pb-3' style={{ fontWeight: 'bold' }}>
          Got a minute to help us improve our samples offering?
        </h1>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ width: 'min-content', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <a className='button is-dark' href='https://survey.alchemer.com/s3/6801210/SW-Sample-Site-Survey' target='_blank' rel="noreferrer" >TAKE THE SURVEY</a>
            <button onClick={() => disableModal()} style={{ border: 'none', fontWeight: 600, backgroundColor: 'unset', cursor: 'pointer' }}><span aria-hidden='true'>NO, THANK YOU</span></button>
          </div>
        </div>
      </div>
    </section>
      : null)
}

export default SurveyModal
