import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getServiceBaseUrl } from '../../Utils/serviceUtils'
import uniq from 'lodash/uniq'
import { axiosWithCreds } from 'app/Utils/ajax.util'

export const checkIfRecaptchaEnabled = createAsyncThunk(
  'checkIfRecaptchaEnabled',
  async () => (await axiosWithCreds.get(`${getServiceBaseUrl}/flags/grecaptchaEnabled`)).data.status
)

const initialState = {
  customErrors: null,
  recaptchaResponse: null,
  recaptchaError: null,
  emptyCartError: null,
  noOrderError: false,
  isLoading: false
}

const orderFormSlice = createSlice({
  name: 'orderForm',
  initialState,
  reducers: {
    setCustomErrors: (state, action) => ({ ...state, customErrors: action.payload }),
    addCustomError: (state, action) => ({ ...state, customErrors: uniq([...(state.customErrors ?? []), action.payload]) }),
    setRecaptchaResponse: (state, action) => ({ ...state, recaptchaResponse: action.payload }),
    setRecaptchaError: (state, action) => ({ ...state, recaptchaError: action.payload }),
    setNoOrderError: (state, action) => ({ ...state, noOrderError: action.payload }),
    setEmptyCartError: (state, action) => ({ ...state, emptyCartError: action.payload }),
    setNoPayableItemsError: (state, action) => ({ ...state, noPayableItemsError: action.payload }),
    setIsLoading: (state, action) => ({ ...state, isLoading: action.payload }),
    // reset to initial state, except for recaptchaEnabled
    resetState: (state) => ({ ...initialState, recaptchaEnabled: state.recaptchaEnabled })
  },
  extraReducers: {
    [checkIfRecaptchaEnabled.pending]: (state) => ({ ...state, recaptchaEnabled: false, isLoading: true }),
    [checkIfRecaptchaEnabled.fulfilled]: (state, action) => ({ ...state, recaptchaEnabled: action.payload === 'true', isLoading: false }),
    [checkIfRecaptchaEnabled.rejected]: (state, action) => ({ ...state, recaptchaError: action.error, isLoading: false })
  }
})

export const {
  addCustomError,
  resetState,
  setCustomErrors,
  setEmptyCartError,
  setIsLoading,
  setNoOrderError,
  setNoPayableItemsError,
  setRecaptchaError,
  setRecaptchaResponse
} = orderFormSlice.actions

export default orderFormSlice.reducer
